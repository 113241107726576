.loading-page {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  background: rgba(0, 0, 0, 0.35);
  z-index: 1031;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}
.loading-page__container__img img {
  max-width: 8vw;
}
@-webkit-keyframes scale-animation {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-animation {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.scale-animation {
  -webkit-animation: scale-animation 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) infinite alternate both;
  animation: scale-animation 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) infinite alternate both;
}
a.btn-home {
  color: /*#ffffff*/ #282c34 !important;
  font-size: 18px;
  font-weight: 400;
  border-color: transparent !important;
  box-shadow: none !important;
}
div.btn-user > button {
  color: /*#ffffff*/ #282c34 !important;
  font-weight: 400;
  border-color: transparent !important;
  box-shadow: none !important;
  background-color: transparent !important;
}
.txt-justify {
  font-size: 18px;
  text-align: justify;
}
.txt-center {
  font-size: 18px;
  text-align: center;
}
.login,
.pageResponse {
  background-image: linear-gradient(to bottom right, #2f4c70, #44a5af);
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}
.pageResponse {
  background-image: none;
}
.pageResponse .card {
  max-width: 800px;
  border: none;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.pageResponse .card .btn.btn-success {
  --bs-btn-bg: #62b6b7;
  --bs-btn-border-color: #62b6b7;
  --bs-btn-disabled-bg: #62b6b7;
  --bs-btn-disabled-border-color: #62b6b7;
  --bs-btn-hover-bg: #08c394;
  --bs-btn-hover-border-color: #08c394;
  --bs-btn-active-bg: #08c394;
  --bs-btn-active-border-color: #08c394;
  font-weight: 500;
}
.pageResponse .card .btn.btn-secondary {
  --bs-btn-bg: #bebebe;
  --bs-btn-border-color: #bebebe;
  --bs-btn-disabled-bg: #bebebe;
  --bs-btn-disabled-border-color: #bebebe;
  --bs-btn-hover-bg: #a2b5bb;
  --bs-btn-hover-border-color: #a2b5bb;
  --bs-btn-active-bg: #a2b5bb;
  --bs-btn-active-border-color: #a2b5bb;
  font-weight: 500;
}
.pageResponse .icon-logo {
  border-radius: 100%;
  margin: auto;
  margin-top: -56px;
  background: #eff5f5;
  height: 100px;
  width: 100px;
  border: solid 2px #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pageResponse b {
  font-weight: 500;
}
.center {
  display: flex;
  justify-content: center;
}
.login-form {
  background-color: rgb(255, 255, 255);
  padding: 30px 20px 30px 20px;
  width: 320px;
  border-radius: 5px;
}
.btn.btn-primary {
  --bs-btn-bg: rgb(53, 123, 163);
  --bs-btn-border-color: rgb(53, 123, 163);
  --bs-btn-hover-bg: rgb(41, 110, 149);
  --bs-btn-hover-border-color: rgb(41, 110, 149);
  --bs-btn-active-bg: rgb(41, 110, 149);
  --bs-btn-active-border-color: rgb(41, 110, 149);
  --bs-btn-disabled-bg: rgb(53, 123, 163);
  --bs-btn-disabled-border-color: rgb(53, 123, 163);
}
.login-form-title {
  font-size: 24px;
  color: rgb(71, 71, 71);
  font-weight: 400;
  text-align: center;
}
nav.navbar {
  /*background-color: #ececec !important;*/
  padding: 0;
}
nav.navbar > div {
  padding-top: 15px;
  padding-bottom: 15px;
}
nav.navbar > div:last-child {
  max-width: 90%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
nav.navbar > div:last-child > div > p {
  font-size: 1.2em;
  font-weight: 500;
  color: #565e64;
}
nav.fixed-top + .page-content {
  margin-top: 145px;
}
nav.fixed-top + .page-content.page-colaborador {
  width: 100%;
}
nav.shadow {
  box-shadow: 0 0.25rem 0.8rem rgba(0, 0, 0, 0.08) !important;
}
nav.fixed-top .navbar-brand span {
  font-family: "Roboto", sans-serif;
}
@media (min-width: 1200px) {
  nav.fixed-top + .page-content {
    width: 85%;
  }
}
@media (min-width: 768px) {
  .mobile-ms {
    margin-left: auto !important;
  }
  .mobile-me {
    margin-right: auto !important;
  }
  .btn-apps {
    min-height: 80px;
    padding: 1rem;
  }
}
@media (max-width: 767px) {
  .hide-mobile {
    display: none;
  }
  #nav-logo-rpa {
    max-width: 30vw;
    height: auto;
  }
  nav.fixed-top > div > div.col.text-end {
    max-width: 50vw;
  }
  .mobile-bt {
    margin-bottom: 1rem;
  }
}
